/* eslint-disable react-hooks/exhaustive-deps */

import {
  DEFAULT_PERPAGE_EDOS,
  EDO_STATUS,
  ERROR_MESSAGE_CODE,
  ERROR_ROLLBACK_TRANSACTION,
  EXCEL_NAME_FILE_FORMAT,
} from 'constants/constants';
import type {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import {Form, Image, Pagination} from 'antd';
import {IChildrenTreeData, TreeDataType, UploadFileType} from './interfaces';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  convertBaseDataToAllTableKeyString,
  extractChildren,
  findIndexByStatus,
  getTimezoneOffset,
} from 'libs/helper';
import {
  edoQueryKeys,
  useCheckUpdateStatusToLogistic,
  useCreateOrUpdateEdo,
  useCreateOrUpdateEdoByLaraApi,
  useExportExcel,
  useGetEdoList,
  useUpdateStatus,
} from './queries';
import {
  renderItemActionBaseOnRole,
  renderModalStyle,
  renderModalStyleWhenFail,
} from './updateStatusItemSetup';

import AngleLeft from 'assets/icons/AngleLeft.svg';
import AngleRight from 'assets/icons/AngleRight.svg';
import {CancelTokenSource} from 'axios';
import ChangeToLogisticIcon from 'assets/icons/changeToLogisticIcon.svg';
import {CustomButtom} from 'components/button/CustomButton';
import {DrawerEditBLCounter} from './components/DrawerEditBLForCounter';
import {DrawerEditBLLogistic} from './components/DrawerEditBLForLogistic';
import {DrawerEdoDetail} from './components/DrawerEdoDetail';
import DrawerFilter from './components/DrawerFilter';
import FilterIcon from 'assets/icons/FilterIcon.svg';
import {FormSelect} from 'components/form/Select';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {ModalConfirmation} from 'components/ModalConfirmation';
import ModalCreateUpdateEdo from './components/CreateUpdateEdo';
import {ModalTransactionHistory} from './components/ModalTransactionHistory';
import ModalUploadImportEdo from './components/ModalUploadImportEdo';
import RedDotICon from 'assets/icons/RedDotICon.svg';
import {RenderButtonActionByRole} from './components/RenderButtonActionByRole';
import ResetAllButtonIcon from 'assets/icons/ResetAllButtonIcon.svg';
import TabItemEmailDetail from './components/ModalTransactionHistory/components/TabItemEmailDetail';
import {TableFirstTimeSkeletonUI} from './components/TableFistTimeSkeletonUI';
import {TableRender} from './components/TableRender';
import {UpdatePayload} from './services';
import {UserRole} from 'interfaces/common-model';
import {authConfig} from 'constants/configs';
import {createOrUdateEdo} from './constants';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';
import {queryClient} from 'App';
import {useCountry} from './components/SelectCountry';
import useDidMountEffect from 'hooks/use-did-mount-effect';
import {useEdoContext} from 'contexts/edo-context';
import {useIsMutating} from 'react-query';
import {useNotification} from 'hooks/use-notification';
import {useUserRole} from './components/SelectRole';
import DepotSelect from 'pages/depot-management/components/DepotSelect';

type CustomerFilters = {
  listBLNo: string[];
  listVesselName: string[];
  listFPOD: string[];
  listActualVoyageNo: string[];
  listLaraVoyageNo: string[];
  listContainerNo: string[];
  listConsignee: string[];
  listReceiveParty: string[];
};

export type ExcelColumnSortType = {
  columnName: string | null;
  isDescending: boolean;
};

const INITIAL_FORM_VALUES: CustomerFilters = {
  listBLNo: [],
  listVesselName: [],
  listFPOD: [],
  listActualVoyageNo: [],
  listLaraVoyageNo: [],
  listContainerNo: [],
  listConsignee: [],
  listReceiveParty: [],
};

export type FiltersEdo = {
  fromBLETADate: string | null;
  toBLETADate: string | null;
  fromUpdateDate: string | null;
  toUpdateDate: string | null;
  fromPickupDate: string | null;
  toPickupDate: string | null;
  fromReturnDate: string | null;
  toReturnDate: string | null;
  listStatus: number[];
  listContainerSize: string[];
  isDG: boolean | null;
  listSOCIndicator: [boolean | null] | [];
  isPL: boolean | null;
  listBLNo?: string[];
  listVesselName?: string[];
  listFPOD?: string[];
  listActualVoyageNo?: string[];
  listLaraVoyageNo?: string[];
  listContainerNo?: string[];
  listConsignee?: string[];
  listReceiveParty?: string[];
  countryName: string;
};

const INITIAL_FILTERS: FiltersEdo = {
  fromBLETADate: null,
  toBLETADate: null,
  fromUpdateDate: null,
  toUpdateDate: null,
  fromPickupDate: null,
  toPickupDate: null,
  fromReturnDate: null,
  toReturnDate: null,
  listStatus: [],
  listContainerSize: [],
  isDG: null,
  listSOCIndicator: [],
  isPL: null,
  listBLNo: [],
  listVesselName: [],
  listFPOD: [],
  listActualVoyageNo: [],
  listLaraVoyageNo: [],
  listContainerNo: [],
  listConsignee: [],
  listReceiveParty: [],
  countryName: '',
};

const EdoMain: React.FC = () => {
  const {edoFilter, isSelectAll, setEdo} = useEdoContext();
  const isMutating = useIsMutating();
  const [form] = Form.useForm<CustomerFilters>();
  const [parentRowSelection, setParentRowSelection] = React.useState<string[]>(
    []
  );
  const currentCountry = useCountry();

  const [childRowSelection, setChildRowSelection] = React.useState<
    IChildrenTreeData[]
  >([]);
  const role = useUserRole();
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [openModalConfirm, setOpenModalConfirm] =
    React.useState<boolean>(false);
  const [openModalActionEdo, setOpenModalActionEdo] =
    React.useState<boolean>(false);
  const [openModalConfirmWhenFail, setOpenModalConfirmWhenFail] =
    React.useState<boolean>(false);
  const [openModalConfirmWhenCancel, setOpenModalConfirmWhenCancel] =
    React.useState<boolean>(false);
  const [showButtonX, setShowButtonX] = React.useState<boolean>(false);
  const [statusType, setStatusType] = React.useState<number>(1);
  const [actionTypeEdo, setActionTypeEdo] = React.useState<number>(
    createOrUdateEdo.Add.value
  );
  const [pageNum, setPageNum] = useState(1);
  const [searchParams, setSearchParams] = useState({
    ...INITIAL_FILTERS,
    countryName: currentCountry,
  });
  const [triggerResetForm, setTriggerResetForm] = useState(false);
  const [OpenUploadFileModal, setOpenUploadFileModal] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
  const [unCheckedItemAfterCheckedAll, setUncheckedItemAfterCheckedAll] =
    useState<string[]>([]);
  const [checkAbleToSendEmailToLogistic, setOpenModalChecker] =
    useState<boolean>(false);
  const [laraError, setLaraError] = useState<{
    isOpen: boolean;
    title: string;
    content: string;
  }>({
    isOpen: false,
    title: '',
    content: '',
  });
  useEffect(() => {
    setEdo((pre) => ({
      ...pre,
      listExceptEdoId: unCheckedItemAfterCheckedAll,
    }));
  }, [unCheckedItemAfterCheckedAll]);

  useEffect(() => {
    setEdo((pre) => ({...pre, isSelectAll: isCheckedAll}));
  }, [isCheckedAll]);

  useEffect(() => {
    setEdo((pre) => ({
      ...pre,
      listExceptEdoId: unCheckedItemAfterCheckedAll,
    }));
  }, [unCheckedItemAfterCheckedAll]);

  const {
    data: edoListData,
    isLoading: isTableLoadingOnUI,
    isFetching: isTableLoading,
    refetch,
  } = useGetEdoList({
    ...searchParams,
    pageNum,
    pageSize: Number(DEFAULT_PERPAGE_EDOS),
    roleEnum: [UserRole.ITOfficer, UserRole.Admin].includes(role)
      ? UserRole.ImportCS
      : role,
    currentTimezone: getTimezoneOffset(),
    countryName: currentCountry,
  });

  useDidMountEffect(() => {
    setIsFiltering(false);
    setChildRowSelection([]);
    setParentRowSelection([]);
    setIsCheckedAll(false);
    setSearchParams({...INITIAL_FILTERS, countryName: currentCountry});
    form.resetFields();
    setTriggerResetForm(!triggerResetForm);
    refetch();
  }, [role]);

  const {handleOpenSuccessNotification, handleOpenFailNotification} =
    useNotification();

  const [isFileDuplicated, setIsFileDuplicated] = useState<boolean>(false);
  const [isLaraFail, setFailLara] = useState<boolean>(false);
  const cancelTokenSource = useRef<CancelTokenSource | undefined>();

  const laraErrorMessageHandling = (
    errorCode: string,
    content: string,
    isCreate: boolean
  ) => {
    switch (errorCode) {
      case ERROR_MESSAGE_CODE.ERROR_00:
      case ERROR_MESSAGE_CODE.ERROR_04:
        setLaraError({
          isOpen: true,
          title: 'Server error',
          content: isCreate
            ? 'LARA API has failed for some records during upload. Please perform check and follow up.'
            : content,
        });
        isCreate && refetch();
        break;
      case ERROR_MESSAGE_CODE.ERROR_02:
        setLaraError({
          isOpen: true,
          title: 'Duplicated container',
          content: content,
        });
        isCreate && refetch();
        break;
      case ERROR_MESSAGE_CODE.ERROR_01:
      case ERROR_MESSAGE_CODE.ERROR_03:
        setLaraError({
          isOpen: true,
          title: 'Request error',
          content: content,
        });
        isCreate && refetch();
        break;
      case ERROR_MESSAGE_CODE.ERROR_05:
        setLaraError({
          isOpen: true,
          title: 'Mapping error',
          content: content,
        });
        isCreate && refetch();
        break;
      default:
        setLaraError({
          isOpen: true,
          title: 'Something went wrong, Please try again!',
          content: 'Please Try again',
        });
        isCreate && refetch();
        break;
    }
  };
  const handleCreateOrUpdateEdo = (payload: UploadFileType) => {
    createOrUpdateEdo(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [edoQueryKeys.GET_CONTAINER_SIZES],
        });
        if (actionTypeEdo === createOrUdateEdo.Add.value) {
          setIsFiltering(false);
          setSearchParams({...INITIAL_FILTERS, countryName: currentCountry});
          form.resetFields();
          setTriggerResetForm(!triggerResetForm);
        }
        setChildRowSelection([]);
        setParentRowSelection([]);
        setIsCheckedAll(false);
        setIsFileDuplicated(false);
        handleOpenSuccessNotification({
          message: 'eDO list has been updated successfully!',
        });
        setShowButtonX(true);
        refetch();
      },
      onError: (err: any) => {
        setShowButtonX(true);
        if (err?.response?.data?.Message) {
          laraErrorMessageHandling(
            err?.response?.data?.Message,
            err.response.data.Data,
            actionTypeEdo === createOrUdateEdo.Add.value
          );
        }
        if (err?.response?.data?.Message === ERROR_MESSAGE_CODE.MSG_04) {
          setIsFileDuplicated(
            err.response.data.Message === ERROR_MESSAGE_CODE.MSG_04
          );
        } else if (
          err.code &&
          err.code === ERROR_ROLLBACK_TRANSACTION.ERR_CANCELED
        ) {
          handleOpenFailNotification({
            message: err.message,
          });
        }
      },
    });
    setOpenUploadFileModal(true);
    setOpenModalActionEdo(false);
  };

  const handleSubmitActionEdo = useCallback(
    (values: FormData | string[], type: 'normal' | 'lara') => {
      setShowButtonX(false);
      if (type === 'normal') {
        const payload = {
          append: values as FormData,
          type: `${
            actionTypeEdo === createOrUdateEdo.Add.value ? 'create' : 'update'
          }`,
          cancelToken: cancelTokenSource,
        };
        setIsFileDuplicated(false);
        handleCreateOrUpdateEdo(payload);
      } else if (type === 'lara') {
        const laraPayload = {
          listBill: values as string[],
          isAdd: actionTypeEdo === createOrUdateEdo.Add.value,
          cancelToken: cancelTokenSource,
        };
        setFailLara(false);
        setIsFileDuplicated(false);
        createOrUpdateEdoByLaraApi(laraPayload, {
          onSuccess: () => {
            setShowButtonX(true);
            queryClient.invalidateQueries({
              queryKey: [edoQueryKeys.GET_CONTAINER_SIZES],
            });
            if (actionTypeEdo === createOrUdateEdo.Add.value) {
              setIsFiltering(false);
              setSearchParams({
                ...INITIAL_FILTERS,
                countryName: currentCountry,
              });
              form.resetFields();
              setTriggerResetForm(!triggerResetForm);
            }
            setChildRowSelection([]);
            setParentRowSelection([]);
            setIsCheckedAll(false);
            setFailLara(false);
            setIsFileDuplicated(false);
            handleOpenSuccessNotification({
              message: 'eDO list has been updated successfully!',
            });
            refetch();
          },
          onError: (err: any) => {
            setShowButtonX(true);
            if (err?.response?.data?.Message) {
              laraErrorMessageHandling(
                err?.response?.data?.Message,
                err.response.data.Data,
                actionTypeEdo === createOrUdateEdo.Add.value
              );
            }
            if (err?.response?.data?.Message === ERROR_MESSAGE_CODE.MSG_05) {
              setFailLara(
                err.response.data.Message === ERROR_MESSAGE_CODE.MSG_05
              );
            } else if (
              err?.response?.data?.Message === ERROR_MESSAGE_CODE.MSG_04
            ) {
              setIsFileDuplicated(
                err.response.data.Message === ERROR_MESSAGE_CODE.MSG_04
              );
            } else if (
              err.code &&
              err.code === ERROR_ROLLBACK_TRANSACTION.ERR_CANCELED
            ) {
              handleOpenFailNotification({
                message: err.message,
              });
            }
          },
        });
        setOpenUploadFileModal(true);
        setOpenModalActionEdo(false);
        //end lara
      }
    },
    [actionTypeEdo]
  );

  useEffect(() => {
    if (!isTableLoadingOnUI && edoListData && isCheckedAll) {
      const [blParentIdArray, childrenArray] =
        convertBaseDataToAllTableKeyString(
          edoListData.data.data,
          unCheckedItemAfterCheckedAll
        );
      setParentRowSelection(blParentIdArray);
      setChildRowSelection(childrenArray);
    }
  }, [isTableLoadingOnUI, isCheckedAll, pageNum, unCheckedItemAfterCheckedAll]);

  const handleClickDownloadOrcancel = (isChecked: boolean) => {
    if (isChecked) {
      setParentRowSelection([]);
      setChildRowSelection([]);
      setIsCheckedAll(false);
    } else {
      const payload = {
        ...searchParams,
        ...currentSortCol,
        currentTimezone: getTimezoneOffset(),
        roleEnum: role,
      };
      exportExcel(payload, {
        onSuccess: (data: any) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `eDO_List_${dayjs(Date.now()).format(EXCEL_NAME_FILE_FORMAT)}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        },
        onError: (error: any) => {},
      });
    }
  };

  const handleOpenModalFilter = () => {
    setOpenFilter(true);
  };

  const handleOnChangePagination = (page: number) => {
    setPageNum(page);
  };

  const handleRefetchEDOList = useCallback(() => {
    setParentRowSelection([]);
    setIsCheckedAll(false);
    setChildRowSelection([]);
    refetch();
  }, [refetch]);

  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const handleApplyFilter = useCallback(
    (value: FiltersEdo, isEmptyAll: boolean) => {
      setParentRowSelection([]);
      setIsCheckedAll(false);
      setChildRowSelection([]);
      setOpenFilter(false);
      setPageNum(1);
      setSearchParams({...value,
        listBLNo: searchParams.listBLNo, 
        listVesselName : searchParams.listVesselName, 
        listFPOD : searchParams.listFPOD, 
        listActualVoyageNo : searchParams.listActualVoyageNo, 
        listLaraVoyageNo : searchParams.listLaraVoyageNo, 
        listContainerNo : searchParams.listContainerNo, 
        listConsignee : searchParams.listConsignee, 
        listReceiveParty : searchParams.listReceiveParty
      });
      setEdo((prev) => ({
        ...prev,
        edoFilter: {...value, 
          listBLNo: searchParams.listBLNo, 
          listVesselName : searchParams.listVesselName, 
          listFPOD : searchParams.listFPOD,
          listActualVoyageNo : searchParams.listActualVoyageNo,
          listLaraVoyageNo : searchParams.listLaraVoyageNo,
          listContainerNo : searchParams.listContainerNo,
          listConsignee : searchParams.listConsignee,
          listReceiveParty : searchParams.listReceiveParty,
        },
      }));
      setIsFiltering(!isEmptyAll);
    },
    [searchParams]
  );

  const handleFormSearch = (values: CustomerFilters) => {
    setParentRowSelection([]);
    setIsCheckedAll(false);
    setChildRowSelection([]);
    setPageNum(1);
    setSearchParams({
      ...searchParams,
      listBLNo: values.listBLNo,
      listVesselName : values.listVesselName,
      listFPOD: values.listFPOD,
      listActualVoyageNo: values.listActualVoyageNo,
      listLaraVoyageNo: values.listLaraVoyageNo,
      listContainerNo: values.listContainerNo,
      listConsignee: values.listConsignee,
      listReceiveParty: values.listReceiveParty
    });
    setEdo((prev) => ({
      ...prev,
      edoFilter: {...searchParams,
        listBLNo: values.listBLNo,
        listVesselName: values.listVesselName,
        listFPOD: values.listFPOD,
        listActualVoyageNo: values.listActualVoyageNo,
        listLaraVoyageNo: values.listLaraVoyageNo,
        listContainerNo: values.listContainerNo,
        listConsignee: values.listConsignee,
        listReceiveParty: values.listReceiveParty,
      },
    }));
  };

  const isChecked = useMemo(() => {
    return !isEmpty(parentRowSelection) || !isEmpty(childRowSelection);
  }, [parentRowSelection, childRowSelection]);

  const {mutate: updateStatus, isLoading: isLoadingUpdateStatus} =
    useUpdateStatus();

  useEffect(() => {
    const mutationCache = queryClient.getMutationCache();
    const mutations = mutationCache.getAll();
    if (findIndexByStatus('loading', mutations) !== -1) {
      setEdo((prve) => ({
        ...prve,
        isLoadingBulkUpdate: true,
      }));
    } else {
      setEdo((prve) => ({
        ...prve,
        isLoadingBulkUpdate: isLoadingUpdateStatus,
      }));
    }
  }, [isLoadingUpdateStatus, isMutating]);

  const {mutate: checkUpdateStatus, isLoading: isLoadingCheckUpdateToLogistic} =
    useCheckUpdateStatusToLogistic();

  const {mutate: exportExcel, isLoading: isLoadingDownloadExcel} =
    useExportExcel();

  const {
    mutate: createOrUpdateEdo,
    isLoading: isLoadingCreateUpdateEdo,
    isSuccess: isSuccessCreateEdo,
    error: errorCreateEdo,
  } = useCreateOrUpdateEdo();

  const {
    mutate: createOrUpdateEdoByLaraApi,
    isLoading: isLoadingCreateUpdateEdoByLaraApi,
    isSuccess: isSuccessCreateEdoByLaraApi,
    error: errorCreateEdoByLaraApi,
  } = useCreateOrUpdateEdoByLaraApi();

  const isSuccessImportFile =
    (errorCreateEdo as any)?.response?.data?.Message ===
    ERROR_MESSAGE_CODE.ERROR_04 ||
    (errorCreateEdoByLaraApi as any)?.response?.data?.Message ===
    ERROR_MESSAGE_CODE.ERROR_04 ||
    (errorCreateEdo as any)?.response?.data?.Message ===
    ERROR_MESSAGE_CODE.ERROR_01 ||
    (errorCreateEdoByLaraApi as any)?.response?.data?.Message ===
    ERROR_MESSAGE_CODE.ERROR_01;

  const handleUpdateStatus = (payload: UpdatePayload) => {
    if (isCheckedAll) {
      setOpenModalChecker(false);
      setOpenModalConfirm(false);
    }

    const conditionalPayload = isCheckedAll
      ? {...payload, queryTracking: true} //for tracking purpose not relative with api
      : payload;
    updateStatus(conditionalPayload, {
      onSuccess: () => {
        setChildRowSelection([]);
        setParentRowSelection([]);
        setPageNum(1);
        setOpenModalChecker(false);
        setOpenModalConfirm(false);
        setIsCheckedAll(false);
        refetch();
      },
      onError: (error: any) => {
        if (error.response.data.Message === ERROR_MESSAGE_CODE.MSG_02) {
          setOpenModalConfirm(false);
          setOpenModalConfirmWhenFail(true);
        }
      },
    });
  };

  const handleCheckUpdateStatus = (payload: UpdatePayload) => {
    checkUpdateStatus(payload, {
      onSuccess: () => {
        handleUpdateStatus(payload);
        setOpenModalChecker(false);
      },
      onError: (error: any) => {
        if (error.response.data.Message === ERROR_MESSAGE_CODE.MSG_01) {
          setOpenModalChecker(true);
        }
      },
    });
  };

  const handleChangeStatus = () => {
    const payload = {
      currentTimezone: getTimezoneOffset(),
      listDOId: extractChildren(childRowSelection),
      status: statusType,
      onListScreen: true, //boolean for BE to know on list screen or update screen
      voyageInfo: null, //default to separated with single update
      blInfo: null, //default to separated with single update
      doInfo: null, //default to separated with single update
      bucketName: authConfig.s3Bucket || '',
      filterRequestModel: {
        ...edoFilter,
        roleEnum: role,
        countryName: currentCountry,
      },
      isSelectAll,
      listExceptEdoId: unCheckedItemAfterCheckedAll,
    };
    handleUpdateStatus(payload);
  };

  const handleConfirmation = useCallback(() => {
    setOpenModalChecker(false);
    const payload = {
      currentTimezone: getTimezoneOffset(),
      listDOId: extractChildren(childRowSelection),
      status: statusType,
      onListScreen: true, //boolean for BE to know on list screen or update screen
      voyageInfo: null, //default to separated with single update
      blInfo: null, //default to separated with single update
      doInfo: null, //default to separated with single update
      bucketName: authConfig.s3Bucket || '',
      filterRequestModel: {
        ...edoFilter,
        roleEnum: role,
        countryName: currentCountry,
      },
      isSelectAll,
      listExceptEdoId: unCheckedItemAfterCheckedAll,
    };
    if (statusType === EDO_STATUS.WaitForLog.value) {
      handleCheckUpdateStatus(payload);
    } else {
      handleUpdateStatus(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    childRowSelection,
    edoListData,
    isTableLoading,
    isTableLoadingOnUI,
    statusType,
  ]);

  const handleCancelLogisticChecker = () => {
    setOpenModalChecker((pre) => !pre);
    setOpenModalConfirm(false);
  };
  const callBackTriggerReadyForRelease = useCallback(() => {
    setStatusType(EDO_STATUS.ReadyForRelease.value);
    setOpenModalConfirm(true);
  }, []);

  const callBackDataDropButton = useCallback((values: ItemType) => {
    setStatusType(Number(values!.key));
    setOpenModalConfirm(true);
  }, []);

  const callBackClickButton = useCallback(() => {
    setStatusType(Number(renderItemActionBaseOnRole(role).firstItem.key));
    setOpenModalConfirm(true);
  }, [role]);

  const callBackDataDropButtonCreateOrUpdateEdo = useCallback(() => {
    setActionTypeEdo(createOrUdateEdo.Update.value);
    setOpenModalActionEdo(true);
  }, []);

  const callBackClickButtonCreateOrUpdateEdo = useCallback(() => {
    setActionTypeEdo(createOrUdateEdo.Add.value);
    setOpenModalActionEdo(true);
  }, []);

  const handleCloseOpenConfirmation = useCallback(() => {
    setOpenModalConfirm(false);
  }, []);

  const [currentSortCol, setCurrentSortCol] = useState<ExcelColumnSortType>({
    columnName: null,
    isDescending: false,
  });

  const handleOnchangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TreeDataType> | SorterResult<TreeDataType>[]
  ) => {
    if (!Array.isArray(sorter)) {
      setCurrentSortCol({
        columnName: sorter.order ? `${sorter.columnKey}` : null,
        isDescending: sorter.order === 'descend',
      });
    }
  };

  const resetAllFilter = () => {
    setSearchParams({...INITIAL_FILTERS, countryName: currentCountry});
    form.resetFields();
    setTriggerResetForm(!triggerResetForm);
    setChildRowSelection([]);
    setParentRowSelection([]);
    setIsCheckedAll(false);
    setOpenModalConfirm(false);
    setIsFiltering(false);
    refetch();
  };

  const handleChange = (value: string[], event: any) => {
    const fieldName = event || "";
    const newValues = value.flatMap((item) =>
      item.includes(",")
        ? item.split(",").map((i) => i.trim()).filter((i) => i !== "")
        : [item]
    );
  
    const uniqueValues = Array.from(new Set(newValues));
    form.setFieldValue(fieldName, uniqueValues)
  };
  
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, fieldName: string) => {
    if (!fieldName) return;
    // if (e.key === ",") {
    //   const input = e.currentTarget.textContent?.trim();
    //   if (input) {
    //     const currentTags = form.getFieldValue(fieldName) || [];
    //     const newTags = Array.from(new Set([...currentTags, input]));
    //     form.setFieldValue(fieldName, newTags);
    //   }
    // }
    if (e.key === "Enter") {
      e.preventDefault();
      form.submit();
    }
  };

  if (currentCountry === '') return <></>;

  return (
    <>
      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-xl leading-6 font-bold font-antonio text-blue-sapphire tracking-[-0.1px] block">
          eDO Management
        </h1>
        <div className="custom-btn flex">
          <RenderButtonActionByRole
            role={role}
            isDisable={
              edoListData ? edoListData?.data.totalRecords === 0 : true
            }
            isLoadingDownloadExcel={isLoadingDownloadExcel}
            isCheckedCheckbox={isChecked}
            handleClickDownloadOrcancel={() =>
              handleClickDownloadOrcancel(isChecked)
            }
            callBackDataDropButton={callBackDataDropButton}
            callBackClickButton={callBackClickButton}
            callBackDataDropButtonCreateOrUpdateEdo={
              callBackDataDropButtonCreateOrUpdateEdo
            }
            callBackClickButtonCreateOrUpdateEdo={
              callBackClickButtonCreateOrUpdateEdo
            }
            callBackTriggerReadyForRelease={callBackTriggerReadyForRelease}
            selectedBLs={parentRowSelection}
          />
        </div>
      </div>
      <div className="custom-table-edo-import bg-[#FDFDFD] border border-solid border-blue-solitude">
        <Form
          initialValues={INITIAL_FORM_VALUES}
          form={form}
          className="flex justify-between flex-row items-center mx-6 my-4"
          onFinish={handleFormSearch}
        >
          <div className="relative">
            {isFiltering && (
              <img
                className="absolute right-[-5px] top-[-5px] z-10"
                src={RedDotICon}
                alt="redotIcon"
              />
            )}
            <CustomButtom
              title="Filter"
              customSize="small"
              customColor="primary"
              variant="outline"
              icon={<img src={FilterIcon} alt="add new account" />}
              customClassName="w-[100px] flex items-center justify-center"
              customTextClassName="text-blue-sapphire"
              onClick={handleOpenModalFilter}
            />
          </div>
          <div className="flex flex-wrap gap-4 items-center" style={{ marginLeft: '1%' }}>
            <div className="!h-[60px] w-[280px] SLpreLEdoSeachBar:w-[500px] preLEdoSeachBar:!w-[200px]">
              {/* <label>BL No.</label> */}
              <FormSelect
                showSearch
                mode="tags"
                label=""
                open={false}
                name="listBLNo"
                suffixIcon={null}
                // placeholder="Search by BL No. (Exact search)"
                placeholder={
                  <>
                  Search by BL No.
                  <br />
                  (Exact search)
                  </>}
                customSelectClassName="w-full [&_.ant-select-selector]:!h-[60px] [&_.ant-select-selector]:!overflow-y-scroll 
                [&_.ant-select-selector]:!overflow-x-hidden [&_.ant-select-selection-overflow]:!overflow-y-auto
                [&_.ant-select-selection-overflow]:!h-auto [&_.ant-select-selection-overflow]:!flex-wrap
                [&_.ant-select-selection-overflow-item]:!w-full [&_.ant-select-selection-overflow-item]:!truncate
                [&_.ant-select-selection-overflow-item]:!block [&_.ant-select-selection-item]:!mt-1 [&_.ant-select-selection-item]:!w-full"
                customFormItemClassName="w-full"
                onKeyDown={(e) => handleKeyDown(e, "listBLNo")}
                onChange={(value) => handleChange(value, "listBLNo")}
              />
            </div>
            <div className="!h-[60px] w-[280px] SLpreLEdoSeachBar:w-[500px] preLEdoSeachBar:!w-[200px]">
              <FormSelect
                showSearch
                mode="tags"
                label=""
                open={false}
                name="listVesselName"
                suffixIcon={null}
                // placeholder="Search by Vessel Name (Exact search)"
                placeholder={
                  <>
                  Search by Vessel Name
                  <br />
                  (Exact search)
                  </>}
                customSelectClassName="w-full [&_.ant-select-selector]:!h-[60px] [&_.ant-select-selector]:!overflow-y-scroll 
                [&_.ant-select-selector]:!overflow-x-hidden [&_.ant-select-selection-overflow]:!overflow-y-auto
                [&_.ant-select-selection-overflow]:!h-auto [&_.ant-select-selection-overflow]:!flex-wrap
                [&_.ant-select-selection-overflow-item]:!w-full [&_.ant-select-selection-overflow-item]:!truncate
                [&_.ant-select-selection-overflow-item]:!block [&_.ant-select-selection-item]:!mt-1 [&_.ant-select-selection-item]:!w-full"
                // customSelectClassName="w-full [&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-x-auto [&_.ant-select-selection-overflow]:scroll [&_.ant-select-selection-overflow]:scrollbar-thin [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
                customFormItemClassName="w-full"
                onKeyDown={(e) => handleKeyDown(e, "listVesselName")}
                onChange={(value) => handleChange(value, "listVesselName")}
              />
            </div>

            
            <div className="!h-[60px] w-[280px] SLpreLEdoSeachBar:w-[500px] preLEdoSeachBar:!w-[200px]">
              <FormSelect
                showSearch
                mode="tags"
                label=""
                open={false}
                name="listActualVoyageNo"
                suffixIcon={null}
                // placeholder="Search by Actual Voyage No. (Exact search)"
                placeholder={
                  <>
                  Search by Actual Voyage No.
                  <br />
                  (Exact search)
                  </>}
                customSelectClassName="w-full [&_.ant-select-selector]:!h-[60px] [&_.ant-select-selector]:!overflow-y-scroll 
                [&_.ant-select-selector]:!overflow-x-hidden [&_.ant-select-selection-overflow]:!overflow-y-auto
                [&_.ant-select-selection-overflow]:!h-auto [&_.ant-select-selection-overflow]:!flex-wrap
                [&_.ant-select-selection-overflow-item]:!w-full [&_.ant-select-selection-overflow-item]:!truncate
                [&_.ant-select-selection-overflow-item]:!block [&_.ant-select-selection-item]:!mt-1 [&_.ant-select-selection-item]:!w-full"
                // customSelectClassName="w-full [&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-x-auto [&_.ant-select-selection-overflow]:scroll [&_.ant-select-selection-overflow]:scrollbar-thin [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
                customFormItemClassName="w-full"
                onKeyDown={(e) => handleKeyDown(e, "listActualVoyageNo")}
                onChange={(value) => handleChange(value, "listActualVoyageNo")}
              />
            </div>
            <div className="!h-[60px] w-[280px] SLpreLEdoSeachBar:w-[500px] preLEdoSeachBar:!w-[200px]">
              <FormSelect
                showSearch
                mode="tags"
                label=""
                open={false}
                name="listLaraVoyageNo"
                suffixIcon={null}
                // placeholder="Search by Lara Voyage No. (Exact search)"
                placeholder={
                  <>
                  Search by Lara Voyage No.
                  <br />
                  (Exact search)
                  </>}
                customSelectClassName="w-full [&_.ant-select-selector]:!h-[60px] [&_.ant-select-selector]:!overflow-y-scroll 
                [&_.ant-select-selector]:!overflow-x-hidden [&_.ant-select-selection-overflow]:!overflow-y-auto
                [&_.ant-select-selection-overflow]:!h-auto [&_.ant-select-selection-overflow]:!flex-wrap
                [&_.ant-select-selection-overflow-item]:!w-full [&_.ant-select-selection-overflow-item]:!truncate
                [&_.ant-select-selection-overflow-item]:!block [&_.ant-select-selection-item]:!mt-1 [&_.ant-select-selection-item]:!w-full"
                // customSelectClassName="w-full [&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-x-auto [&_.ant-select-selection-overflow]:scroll [&_.ant-select-selection-overflow]:scrollbar-thin [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
                customFormItemClassName="w-full"
                onKeyDown={(e) => handleKeyDown(e, "listLaraVoyageNo")}
                onChange={(value) => handleChange(value, "listLaraVoyageNo")}
              />
            </div>
            <div className="!h-[60px] w-[280px] SLpreLEdoSeachBar:w-[500px] preLEdoSeachBar:!w-[200px]">
              <FormSelect
                showSearch
                mode="tags"
                label=""
                open={false}
                name="listContainerNo"
                suffixIcon={null}
                placeholder={
                  <>
                  Search by Container No.
                  <br />
                  (Exact search)
                  </>}
                  customSelectClassName="w-full [&_.ant-select-selector]:!h-[60px] [&_.ant-select-selector]:!overflow-y-scroll 
                  [&_.ant-select-selector]:!overflow-x-hidden [&_.ant-select-selection-overflow]:!overflow-y-auto
                  [&_.ant-select-selection-overflow]:!h-auto [&_.ant-select-selection-overflow]:!flex-wrap
                  [&_.ant-select-selection-overflow-item]:!w-full [&_.ant-select-selection-overflow-item]:!truncate
                  [&_.ant-select-selection-overflow-item]:!block [&_.ant-select-selection-item]:!mt-1 [&_.ant-select-selection-item]:!w-full"
                  // customSelectClassName="w-full [&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-x-auto [&_.ant-select-selection-overflow]:scroll [&_.ant-select-selection-overflow]:scrollbar-thin [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
                  customFormItemClassName="w-full"
                  onKeyDown={(e) => handleKeyDown(e, "listContainerNo")}
                  onChange={(value) => handleChange(value, "listContainerNo")}
              />
            </div>
            <div className="!h-[60px] w-[280px] SLpreLEdoSeachBar:w-[500px] preLEdoSeachBar:!w-[200px]">
              <FormSelect
                showSearch
                mode="tags"
                label=""
                open={false}
                name="listConsignee"
                suffixIcon={null}
                // placeholder="Search by Consignee (Partial search allowed)"
                placeholder={
                  <>
                  Search by Consignee
                  <br />
                  (Partial search allowed)
                  </>}
                customSelectClassName="w-full [&_.ant-select-selector]:!h-[60px] [&_.ant-select-selector]:!overflow-y-scroll 
                [&_.ant-select-selector]:!overflow-x-hidden [&_.ant-select-selection-overflow]:!overflow-y-auto
                [&_.ant-select-selection-overflow]:!h-auto [&_.ant-select-selection-overflow]:!flex-wrap
                [&_.ant-select-selection-overflow-item]:!w-full [&_.ant-select-selection-overflow-item]:!truncate
                [&_.ant-select-selection-overflow-item]:!block [&_.ant-select-selection-item]:!mt-1 [&_.ant-select-selection-item]:!w-full"
                // customSelectClassName="w-full [&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-x-auto [&_.ant-select-selection-overflow]:scroll [&_.ant-select-selection-overflow]:scrollbar-thin [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
                customFormItemClassName="w-full"
                onKeyDown={(e) => handleKeyDown(e, "listConsignee")}
                onChange={(value) => handleChange(value, "listConsignee")}
              />
            </div>
            <div className="!h-[60px] w-[280px] SLpreLEdoSeachBar:w-[500px] preLEdoSeachBar:!w-[200px]">
              <FormSelect
                showSearch
                mode="tags"
                label=""
                open={false}
                name="listReceiveParty"
                suffixIcon={null}
                // placeholder="Search by Receiving Party (Partial search allowed)"
                placeholder={
                  <>
                  Search by Receiving Party
                  <br />
                  (Partial search allowed)
                  </>}
                customSelectClassName="w-full [&_.ant-select-selector]:!h-[60px] [&_.ant-select-selector]:!overflow-y-scroll 
                [&_.ant-select-selector]:!overflow-x-hidden [&_.ant-select-selection-overflow]:!overflow-y-auto
                [&_.ant-select-selection-overflow]:!h-auto [&_.ant-select-selection-overflow]:!flex-wrap
                [&_.ant-select-selection-overflow-item]:!w-full [&_.ant-select-selection-overflow-item]:!truncate
                [&_.ant-select-selection-overflow-item]:!block [&_.ant-select-selection-item]:!mt-1 [&_.ant-select-selection-item]:!w-full"
                // customSelectClassName="w-full [&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-x-auto [&_.ant-select-selection-overflow]:scroll [&_.ant-select-selection-overflow]:scrollbar-thin [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
                customFormItemClassName="w-full"
                onKeyDown={(e) => handleKeyDown(e, "listReceiveParty")}
                onChange={(value) => handleChange(value, "listReceiveParty")}
              />
            </div>
            <div className="!h-[60px] w-[280px] SLpreLEdoSeachBar:w-[500px] preLEdoSeachBar:!w-[200px] cursor-pointer">
            {/* <div className="!h-[60px] w-[280px] SLpreLEdoSeachBar:w-[500px] preLEdoSeachBar:!w-[200px]"> */}
              <DepotSelect
                      name="listFPOD"
                      allowClear
                      placeholder="Select FPOD"
                      customFormItemClassName='[&_.ant-select-selector]:!h-[40px]'
                      filterDepots={(depot) => depot.status}
                      // customSelectClassName="w-full [&_.ant-select-selector]:!h-[60px] [&_.ant-select-selector]:!overflow-y-scroll 
                      // [&_.ant-select-selector]:!overflow-x-hidden [&_.ant-select-selection-overflow]:!overflow-y-auto
                      // [&_.ant-select-selection-overflow]:!h-auto [&_.ant-select-selection-overflow]:!flex-wrap
                      // [&_.ant-select-selection-overflow-item]:!w-full [&_.ant-select-selection-overflow-item]:!truncate
                      // [&_.ant-select-selection-overflow-item]:!block [&_.ant-select-selection-item]:!mt-1 [&_.ant-select-selection-item]:!w-full [&_.ant-select-arrow]:hidden"
                      customSelectClassName="w-full [&_.ant-select-selection-overflow]:!flex-nowrap [&_.ant-select-selection-overflow]:!overflow-x-auto [&_.ant-select-selection-overflow]:scroll [&_.ant-select-selection-overflow]:scrollbar-thin [&_.ant-select-selection-overflow-item:first-child_.ant-select-selection-item]:!ml-1.5"
                      // onKeyDown={(e) => handleKeyDown(e, "listFPOD")}
                      // onChange={(value) => handleChange(value, "listFPOD")}
                      onSelect={(value) => form.setFieldValue("listFPOD", [value])}
                    />
            </div>
            <Image
              preview={false}
              onClick={resetAllFilter}
              className="cursor-pointer !h-10 !w-10 mx-3"
              src={ResetAllButtonIcon}
              alt="resetIcon"
            />
            <Form.Item className="!mb-0">
              <CustomButtom
                title="Apply"
                customSize="small"
                customColor="primary"
                htmlType="submit"
              />
            </Form.Item>
            <DrawerFilter
              isTriggerResetCallBack={triggerResetForm}
              open={openFilter}
              setOpen={setOpenFilter}
              callBackApply={handleApplyFilter}
            />
          </div>
        </Form>
        {!isTableLoadingOnUI ? (
          <TableRender
            callBackOnChangeTable={handleOnchangeTable}
            childRowSelection={childRowSelection}
            setParentRowSelection={setParentRowSelection}
            setChildRowSelection={setChildRowSelection}
            parentRowSelection={parentRowSelection}
            edoListData={edoListData?.data.data || []}
            isTableLoading={isTableLoading}
            setIsCheckedAll={setIsCheckedAll}
            setUncheckedItemAfterCheckedAll={setUncheckedItemAfterCheckedAll}
            unCheckedItemAfterCheckedAll={unCheckedItemAfterCheckedAll}
            isCheckedAll={isCheckedAll}
          />
        ) : (
          <TableFirstTimeSkeletonUI isTableLoadingOnUI={true} />
        )}
        {edoListData && edoListData?.data?.totalPages >= 1 && (
          <div className="p-[24px] flex justify-between items-center border border-blue-hawkes-blue bg-white">
            <div>
              <span className="font-roboto text-[14px] not-italic font-normal leading-4 text-blue-waikawa-grey">
                Results:{' '}
              </span>
              <span className="text-blue-midnight-express text-[14px] not-italic font-normal left-5">
                {edoListData?.data.totalChildrenRecords}
              </span>
            </div>
            <div className="custom-pagination-customer-management">
              <Pagination
                pageSize={edoListData?.data?.pageSize}
                current={pageNum}
                onChange={handleOnChangePagination}
                total={edoListData?.data?.totalRecords}
                nextIcon={<img alt="pagination-next" src={AngleRight} />}
                prevIcon={<img alt="pagination-previous" src={AngleLeft} />}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
        <ModalConfirmation
          isLoadingBtn={
            isLoadingCheckUpdateToLogistic ||
            isLoadingUpdateStatus ||
            isLoadingCheckUpdateToLogistic
          }
          isDisableButton={
            isLoadingCheckUpdateToLogistic ||
            isLoadingUpdateStatus ||
            isLoadingCheckUpdateToLogistic
          }
          open={openModalConfirm}
          okText={renderModalStyle(statusType)!.confirmText}
          customImage={
            <img alt="logout-img" src={renderModalStyle(statusType)!.icon} />
          }
          onCancel={handleCloseOpenConfirmation}
          onOk={handleConfirmation}
          cancelText={'Cancel'}
          title={renderModalStyle(statusType)!.title}
          description={renderModalStyle(statusType)!.description}
          subDescription={renderModalStyle(statusType)!.subDescription}
          customClassName={'flex justify-center'}
        />
        <ModalConfirmation
          open={openModalConfirmWhenFail}
          okText={renderModalStyleWhenFail(statusType)!.confirmText}
          customImage={
            <img
              alt="logout-img"
              src={renderModalStyleWhenFail(statusType)!.icon}
            />
          }
          isCancelOnly
          onCancel={() => setOpenModalConfirmWhenFail(false)}
          onOk={() => setOpenModalConfirmWhenFail(false)}
          cancelText={'Cancel'}
          title={renderModalStyleWhenFail(statusType)!.title}
          description={renderModalStyleWhenFail(statusType)!.description}
          subDescription={renderModalStyleWhenFail(statusType)!.subDescription}
          customClassName={'w-full'}
        />
        <ModalConfirmation
          open={openModalConfirmWhenCancel}
          okText={'Confirm'}
          onCancel={() => setOpenModalConfirmWhenCancel(false)}
          onOk={() => {
            setOpenModalConfirmWhenCancel(false);
            if (showButtonX) {
              handleOpenFailNotification({
                message: 'Cannot cancel the request at the moment!',
              });
              return;
            }
            setOpenUploadFileModal(false);
            cancelTokenSource.current?.cancel('Process has been cancelled');
          }}
          cancelText={'Cancel'}
          title={'Cancel process'}
          description={
            'Are you sure you want to cancel this process? All completed eDOs will be reverted and have to be uploaded again'
          }
          subDescription={''}
          customClassName={'flex justify-center'}
        />
        {openModalActionEdo && (
          <ModalCreateUpdateEdo
            modalTitle={
              actionTypeEdo === createOrUdateEdo.Add.value
                ? 'Add New eDO'
                : 'Update eDO'
            }
            subTitle={
              actionTypeEdo === createOrUdateEdo.Add.value
                ? 'Submit information to add new eDOs to the system'
                : 'Submit information to update eDOs'
            }
            callBackSuccess={handleSubmitActionEdo}
            isOpen={openModalActionEdo}
            setOpen={() => setOpenModalActionEdo(false)}
            isCreationEdo={actionTypeEdo === createOrUdateEdo.Add.value}
            isSuccessConfirmation={
              isSuccessCreateEdo || isSuccessCreateEdoByLaraApi
            }
            isLoadingSubmit={
              isLoadingCreateUpdateEdo || isLoadingCreateUpdateEdoByLaraApi
            }
          />
        )}
      </div>
      {OpenUploadFileModal && (
        <ModalUploadImportEdo
          showButtonX={showButtonX}
          isLoading={
            isLoadingCreateUpdateEdo || isLoadingCreateUpdateEdoByLaraApi
          }
          isSuccess={
            isSuccessCreateEdo ||
            isSuccessCreateEdoByLaraApi ||
            isSuccessImportFile
          }
          callBackClose={() => {
            setOpenUploadFileModal(false);
          }}
          callBackCancel={() => {
            setOpenModalConfirmWhenCancel(true);
          }}
          isDuplicated={isFileDuplicated}
          isLaraFail={isLaraFail}
          isImport={actionTypeEdo === createOrUdateEdo.Add.value}
        />
      )}
      <DrawerEditBLLogistic
        selectedBLs={extractChildren(childRowSelection)}
        refetchEdoList={handleRefetchEDOList}
      />
      <DrawerEditBLCounter
        selectedBLs={extractChildren(childRowSelection)}
        refetchEdoList={handleRefetchEDOList}
      />
      <DrawerEdoDetail refetchEdoList={handleRefetchEDOList} />
      <ModalTransactionHistory />
      <TabItemEmailDetail />
      <ModalConfirmation
        isDisableButton={!isCheckedAll && isLoadingUpdateStatus}
        open={checkAbleToSendEmailToLogistic}
        onOk={handleChangeStatus}
        onCancel={handleCancelLogisticChecker}
        customImage={
          <Image
            preview={false}
            alt="modal-confirm-change-edo-status-icon"
            src={ChangeToLogisticIcon}
          />
        }
        okText="Confirm"
        cancelText="Cancel"
        title={'No Logistic email found'}
        description={
          'We cannot found matching Logistic email(s) associate with selected Depot(s). Do you still want to continue proceed and change status without sending email to Logistic team?'
        }
      />
      {/* lara popup handling */}
      <ModalConfirmation
        open={laraError.isOpen}
        onOk={() => setLaraError((preve) => ({...preve, isOpen: false}))}
        customImage={
          <Image
            preview={false}
            alt="modal-confirm-change-edo-status-icon"
            src={ChangeToLogisticIcon}
          />
        }
        isCancelOnly={true}
        okText="OK"
        title={laraError.title}
        description={laraError.content}
        customClassName={'w-full'}
      />
    </>
  );
};

export default EdoMain;
